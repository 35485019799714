import { Autocomplete, TextField, Typography } from '@mui/material'
import React from 'react'

const list = [
  { label: 'Merry Gold' },
  { label: 'Merry G' },
  { label: 'Marc Gol' },
  { label: 'Merry lea' },
];

const CommonAutocomplete = (props) => {
  const { labelinput, data, name, onChange, value, formik, placeholder } = props
  return (
    <>
      {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
      <Autocomplete
        disablePortal
        fullWidth
        // multiple={props.multiple}
        id="combo-box-demo"
        options={data || list}
        sx={{ width: '100%' }}
        value={value == '' ? null : value}
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        error={formik?.touched[props.name] && formik?.errors[props.name]}
        renderInput={(params) => <TextField {...params}
          error={formik?.touched[props.name] && formik?.errors[props.name]}
          helperText={formik?.touched[props.name] && formik?.errors[props.name]}
          label="" placeholder={placeholder} fullWidth />}
      />
    </>
  )
}

export default CommonAutocomplete