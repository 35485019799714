import { get, post, deleteRequest, put } from "../web.request";

export const brandDropdown = async () => {
  return await get(`/brandDropdown`);
}

export const brandList = async (query) => {
  return await get(`/brandList${query}`);
}

export const changeBrandStatus = async (id) => {
  return await put(`/statusChangeBrand/${id}`);
}

export const deleteBrand = async (id) => {
  return await deleteRequest(`/deleteBrand/${id}`);
}

export const brandDetail = async (id) => {
  return await get(`/brandDetail/${id}`);
}

export const addBrand = async (data) => {
  return await post(`/createBrand`, data);
}

export const updateBrand = async (id, data) => {
  return await put(`/updateBrand/${id}`, data);
}