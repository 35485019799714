import { Box, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Button, Heading, Modal } from '../../components/Common'
import { purchaseOrderDetail } from '../../Service/Purchase.service'
import { useLocation } from 'react-router-dom'
import Logo from '../../assets/images/Logo.png'
import { RiEditCircleLine } from 'react-icons/ri'
import { MdDownloading } from 'react-icons/md'
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { useDispatch } from 'react-redux'

const PBillsDetails = () => {
  const { state } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });

  const dispatch = useDispatch()

  const [purchaseOrder, setPurchaseOrder] = React.useState({})
  useEffect(() => {
    if (state && state?.id) {
      purchaseOrderData(state.id)
    }
  }, [])



  const purchaseOrderData = async (id) => {
    const res = await purchaseOrderDetail(id)
    if (res?.status) {
      setPurchaseOrder(res?.data)
    } else {
      setPurchaseOrder({})
    }
  }

  function aggregateTaxes(products) {
    if (!products) return [];

    const taxMap = new Map();

    products.forEach(product => {
      if (product && product.taxName) {
        const taxCalculateValue = parseFloat(product.taxCalculateValue) || 0;
        const taxValue = parseFloat(product.taxValue) || 0;
        const taxExtraValue = parseFloat(product.taxExtraValue) || 0;

        if (taxMap.has(product.taxName)) {
          const existing = taxMap.get(product.taxName);
          taxMap.set(product.taxName, {
            taxCalculateValue: existing.taxCalculateValue + taxCalculateValue,
            taxValue: existing.taxValue + taxValue,
            taxExtraValue: existing.taxExtraValue + taxExtraValue
          });
        } else {
          taxMap.set(product.taxName, {
            taxCalculateValue,
            taxValue,
            taxExtraValue
          });
        }
      }
    });

    return Array.from(taxMap, ([taxName, values]) => ({
      taxName,
      taxCalculateValue: values.taxCalculateValue,
      taxValue: values.taxValue,
      taxExtraValue: values.taxExtraValue
    }));
  }

  const handleDownload = async (purchaseOrder, datas) => {
    const data = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title></title>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

    body {
      font-family: "Montserrat", sans-serif !important;
    }

    .container {
      margin: 0 auto;
      max-width: 210mm;
      /* A4 width */
      height: 275mm;
      /* A4 height */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content {
      display: flex;
      justify-content: space-between;
    }

    .content-left {
      margin-top: 10px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .content-right {
      width: 50%;
    }

    .logo {
      height: auto;
      width: 200px;
    }

    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }

    h2 {
      font-size: 25px;
      font-weight: 700;
      line-height: 33px;
      margin: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    .top-right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .date-info,
    .left,
    .right {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
    }

    .date-info p,
    .left p,
    .right p {
      font-size: 12px;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      line-height: 22px;
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.32);
      margin: 3px 0;
    }

    .description {
      margin-top: 50px;
    }

    .table {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }

    .col-desc,
    .col-taken,
    .col-number,
    .col-discount,
    .col-mva,
    .col-amount {
      text-align: center;
      padding: 6px;
      font-weight: 700;
    }

    .col-desc {
      text-align: left;
      font-weight: 700;
    }

    .table_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400 !important;
    }

    .col-desc_det {
      text-align: start;
      padding: 6px;
      font-weight: 400;
    }

    .col-taken_det,
    .col-number_det,
    .col-discount_det,
    .col-mva_det,
    .col-amount_det {
      text-align: center;
      padding: 6px;
      font-weight: 400;
    }

    .col-amount,
    .col-amount_det {
      text-align: end;
    }

    .eu-control {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      font-size: 12px;
    }

    .left,
    .center,
    .right {
      text-align: center;
    }

    .table-summary {
      display: grid;
      grid-template-columns: 2fr 2fr;
      font-size: 12px;
    }

    .to-pay {
      display: flex;
      justify-content: space-between;
      padding: 6px;
    }

    .to-pay p {
      font-size: 14px;
      font-weight: 700;
    }

    .payment-section {
      padding: 0px;
    }

    .cash-invoice {
      font-size: 12px;
      font-weight: 500;
    }

    .payment-info {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-right: 10px;
    }

    .blue-line {
      width: 100%;
      height: 15px;
      background: #ffc107;
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      margin: 12px 0;
    }

    .payment-detail {
      display: flex;
      justify-content: flex-start;
    }

    .detail-label {
      font-size: 15px;
      font-weight: 500;
      width: 170px;
    }

    .detail-value {
      font-size: 15px;
      font-weight: 500;
    }

    .divider {
      border: 1px solid #000;
    }

    .company-info {
      font-size: 11.5px;
      font-weight: 500;
      margin-top: 0px;
    }

    .bolder {
      font-weight: 700;
    }

    .eu_control_div {
      padding: 3px 6px;
      text-align: center;
    }

    .eu_control_div p {
      line-height: 20px;
    }

    .eu_control_div:first-child {
      text-align: start;
    }

    .eu_control_div:last-child {
      text-align: end;
    }

    .table-summary_left {
      padding: 0 6px;
      line-height: 20px !important;
    }

    .table-summary_right {
      text-align: end;
      padding: 0 6px;
      line-height: 20px !important;
    }

    .eu-control p,
    .table-summary p {
      font-size: 12px;
      line-height: 18px;
    }

    .eu-control,
    .table-summary {
      padding: 5px 0;
    }
  </style>
</head>

<body>
  <div class="container">
    <div>
      <div class="content">
        <div style="width: 100%;">
          <div style="display: flex; justify-content: space-between;width: 100%;">
            <div>
              <h2>PURCHASE ORDER</h2>
              <div style="margin-top: 10px;">
                <div style="display: flex;">
                  <p style="width: 130px;">Bill number :</p>
                  <p><b>${purchaseOrder?.orderNumber}</b></p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">Order date :</p>
                  <p>${moment(purchaseOrder?.createdAt).format('DD-MM-YYYY')}</p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">VAT Number :</p>
                  <p>23AAQFN2342D1Z1</p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">Company name :</p>
                  <p>ANC Company</p>
                </div>
              </div>
            </div>
            <div>
              <img src=${Logo} class='logo' width="250" alt="Company Logo" />
            </div>
          </div>
          <div style="margin-top: 50px; display: flex;justify-content:space-between">
            <div style="width: 50%">
              <p><b>Nettgrow</b></p>
              <p>101 Townsend Street <br />
                San Francisco, California 94107 <br />
                United States</p>
              <p>billing@nettgrow.com</p>
              <p>VAT No. : 23AAQFN2342D1Z1</p>
            </div>
            <div style="width: 50%;">
              <p><b>Supplier</b></p>
              <p>${purchaseOrder?.venderId?.companyName || ""}</p>
              <p>${purchaseOrder?.venderId?.address1 ? purchaseOrder?.venderId?.address1 + ',' : ''}
                ${purchaseOrder?.venderId?.address2 ? purchaseOrder?.venderId?.address2 + ',' : ''}
                ${purchaseOrder?.venderId?.cityId?.Name ? purchaseOrder?.venderId?.cityId?.Name + ',' : ''}
                ${purchaseOrder?.venderId?.stateId?.Name ? purchaseOrder?.venderId?.stateId?.Name + ',' : ''}
                ${purchaseOrder?.venderId?.zipcode ? purchaseOrder?.venderId?.zipcode + ',' : ''}</p>
              <p>${purchaseOrder?.venderId?.email ? purchaseOrder?.venderId?.email : ''}</p>
              <p>VAT No. : 23AAQFN2342D1Z1</p>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div class="table">
          <div style="width: 45%">
            <div class="col-desc">Product</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken" style="width: 80px">Price</div>
            <div class="col-number" style="width: 70px">Tax</div>
            <div class="col-discount" style="width: 90px">Quantity</div>
            <div class="col-amount" style="width: 90px">AMOUNT</div>
          </div>
        </div>
        <hr style="border:1px solid #000;" />

    ${purchaseOrder?.productId && purchaseOrder?.productId.length > 0 && purchaseOrder?.productId.map((data, index) => `
        <div class="table_data">
          <div style="width: 45%">
            <div class="col-desc_det">${data?.productId?.productName}</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken_det" style="width: 80px">&#8377; ${data?.perProductPrice}</div>
            <div class="col-number_det" style="width: 70px">
  &#8377;${data?.productId?.tax} (${data?.taxValue}%)
</div>

            <div class="col-discount_det" style="width: 90px">${data?.quantity}</div>
            <div class="col-amount_det" style="width: 90px">&#8377; ${data?.perProductPrice * data?.quantity}</div>
          </div>
        </div>
        `).join('')}

        <hr />
        <div style="display: flex; justify-content: space-between">
          <div class="" style="width: 43%">
          </div>
          <div style="width: 50%; margin-top: 10px;">
            <div class="table-summary">
              <div class="table-summary_left">
              <p>Subtotal</p>
              <p>${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxName}@${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxValue + '%'}</p >
                <p>Discount</p>
                <p>Adjustment</p>
              </div>
              <div class="table-summary_right">
              <p>&#8377;${purchaseOrder?.subtotal}</p>
                <p>&#8377; ${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxCalculateValue}</p>
                <p>- &#8377; ${purchaseOrder?.discountAmount}</p>
                <p>${purchaseOrder.adjustmentType == 1 ? '+' : '-'} &#8377; ${purchaseOrder?.adjustment}</p>
              </div>
            </div>
            <hr style="border:1px solid #000" />
            <div class="to-pay">
              <p>Payable Amount</p>
              <p>&#8377; ${purchaseOrder?.payableAmount}</p>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 50px;display: flex;flex-direction: column;gap: 10px;">
        <p>* This is computer generated document, does not require any signature.</p>
      </div>
    </div>
  </div>
</body>

</html>
  ` ;

    html2pdf()
      .set({
        filename: datas ? `Bill-${data.billNumber}.pdf` : `Bill-${purchaseOrder?.billNumber}.pdf`,
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 4, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .from(data)
      .save()
    purchaseOrderData(purchaseOrder.id)

  }

  console.log('purchaseOrder', aggregateTaxes(purchaseOrder.productId))

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head='Bill Details' />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <Button buttonName='Download' startIcon={<MdDownloading style={{ fontSize: '14px' }} />} onClick={() => {
            handleDownload(purchaseOrder)
          }} />
        </Stack>
      </Stack>
      <div style={{
        marginTop: '50px',
      }} dangerouslySetInnerHTML={{
        __html: `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title></title>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

    body {
      font-family: "Montserrat", sans-serif !important;
    }

    .container {
      margin: 0 auto;
      max-width: 210mm;
      /* A4 width */
      height: 275mm;
      /* A4 height */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content {
      display: flex;
      justify-content: space-between;
    }

    .content-left {
      margin-top: 10px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .content-right {
      width: 50%;
    }

    .logo {
      height: auto;
      width: 200px;
    }

    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }

    h2 {
      font-size: 25px;
      font-weight: 700;
      line-height: 33px;
      margin: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    .top-right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .date-info,
    .left,
    .right {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
    }

    .date-info p,
    .left p,
    .right p {
      font-size: 12px;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      line-height: 22px;
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.32);
      margin: 3px 0;
    }

    .description {
      margin-top: 50px;
    }

    .table {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }

    .col-desc,
    .col-taken,
    .col-number,
    .col-discount,
    .col-mva,
    .col-amount {
      text-align: center;
      padding: 6px;
      font-weight: 700;
    }

    .col-desc {
      text-align: left;
      font-weight: 700;
    }

    .table_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400 !important;
    }

    .col-desc_det {
      text-align: start;
      padding: 6px;
      font-weight: 400;
    }

    .col-taken_det,
    .col-number_det,
    .col-discount_det,
    .col-mva_det,
    .col-amount_det {
      text-align: center;
      padding: 6px;
      font-weight: 400;
    }

    .col-amount,
    .col-amount_det {
      text-align: end;
    }

    .eu-control {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      font-size: 12px;
    }

    .left,
    .center,
    .right {
      text-align: center;
    }

    .table-summary {
      display: grid;
      grid-template-columns: 2fr 2fr;
      font-size: 12px;
    }

    .to-pay {
      display: flex;
      justify-content: space-between;
      padding: 6px;
    }

    .to-pay p {
      font-size: 14px;
      font-weight: 700;
    }

    .payment-section {
      padding: 0px;
    }

    .cash-invoice {
      font-size: 12px;
      font-weight: 500;
    }

    .payment-info {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-right: 10px;
    }

    .blue-line {
      width: 100%;
      height: 15px;
      background: #ffc107;
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      margin: 12px 0;
    }

    .payment-detail {
      display: flex;
      justify-content: flex-start;
    }

    .detail-label {
      font-size: 15px;
      font-weight: 500;
      width: 170px;
    }

    .detail-value {
      font-size: 15px;
      font-weight: 500;
    }

    .divider {
      border: 1px solid #000;
    }

    .company-info {
      font-size: 11.5px;
      font-weight: 500;
      margin-top: 0px;
    }

    .bolder {
      font-weight: 700;
    }

    .eu_control_div {
      padding: 3px 6px;
      text-align: center;
    }

    .eu_control_div p {
      line-height: 20px;
    }

    .eu_control_div:first-child {
      text-align: start;
    }

    .eu_control_div:last-child {
      text-align: end;
    }

    .table-summary_left {
      padding: 0 6px;
      line-height: 20px !important;
    }

    .table-summary_right {
      text-align: end;
      padding: 0 6px;
      line-height: 20px !important;
    }

    .eu-control p,
    .table-summary p {
      font-size: 12px;
      line-height: 18px;
    }

    .eu-control,
    .table-summary {
      padding: 5px 0;
    }
  </style>
</head>

<body>
  <div class="container">
    <div>
      <div class="content">
        <div style="width: 100%;">
          <div style="display: flex; justify-content: space-between;width: 100%;">
            <div>
              <h2>PURCHASE ORDER</h2>
              <div style="margin-top: 10px;">
                <div style="display: flex;">
                  <p style="width: 130px;">Bill number :</p>
                  <p><b>${purchaseOrder?.orderNumber}</b></p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">Order date :</p>
                  <p>${moment(purchaseOrder?.createdAt).format('DD-MM-YYYY')}</p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">VAT Number :</p>
                  <p>23AAQFN2342D1Z1</p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">Company name :</p>
                  <p>ANC Company</p>
                </div>
              </div>
            </div>
            <div>
              <img src=${Logo} class='logo' width="200" alt="Company Logo" />
            </div>
          </div>
          <div style="margin-top: 50px; display: flex;justify-content:space-between">
            <div style="width: 50%">
              <p><b>Nettgrow</b></p>
              <p>101 Townsend Street <br />
                San Francisco, California 94107 <br />
                United States</p>
              <p>billing@nettgrow.com</p>
              <p>VAT No. : 23AAQFN2342D1Z1</p>
            </div>
            <div style="width: 50%;">
              <p><b>Supplier</b></p>
              <p>${purchaseOrder?.venderId?.companyName || ""}</p>
              <p>${purchaseOrder?.venderId?.address1 ? purchaseOrder?.venderId?.address1 + ',' : ''}
                ${purchaseOrder?.venderId?.address2 ? purchaseOrder?.venderId?.address2 + ',' : ''}
                ${purchaseOrder?.venderId?.cityId?.Name ? purchaseOrder?.venderId?.cityId?.Name + ',' : ''}
                ${purchaseOrder?.venderId?.stateId?.Name ? purchaseOrder?.venderId?.stateId?.Name + ',' : ''}
                ${purchaseOrder?.venderId?.zipcode ? purchaseOrder?.venderId?.zipcode + ',' : ''}</p>
              <p>${purchaseOrder?.venderId?.email ? purchaseOrder?.venderId?.email : ''}</p>
              <p>VAT No. : 23AAQFN2342D1Z1</p>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div class="table">
          <div style="width: 45%">
            <div class="col-desc">Product</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken" style="width: 80px">Price</div>
            <div class="col-number" style="width: 70px">Tax</div>
            <div class="col-discount" style="width: 90px">Quantity</div>
            <div class="col-amount" style="width: 90px">AMOUNT</div>
          </div>
        </div>
        <hr style="border:1px solid #000;" />

    ${purchaseOrder?.productId && purchaseOrder?.productId.length > 0 && purchaseOrder?.productId.map((data, index) => `
        <div class="table_data">
          <div style="width: 45%">
            <div class="col-desc_det">${data?.productId?.productName}</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken_det" style="width: 80px">&#8377; ${data?.perProductPrice}</div>
            <div class="col-number_det" style="width: 70px"> &#8377;${data?.tax + ' (' + data?.taxValue +
          '%)'}</div>
            <div class="col-discount_det" style="width: 90px">${data?.quantity}</div>
            <div class="col-amount_det" style="width: 90px">&#8377; ${data?.perProductPrice * data?.quantity}</div>
          </div>
        </div>
        `).join('')}

        <hr />
        <div style="display: flex; justify-content: space-between">
          <div class="" style="width: 43%">
          </div>
          <div style="width: 50%; margin-top: 10px;">
            <div class="table-summary">
              <div class="table-summary_left">
              <p>Subtotal</p>
                <p>${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxName}@${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxValue + '%'}</p >
                <p>Discount</p>
                <p>Adjustment</p>
              </div >
  <div class="table-summary_right">
    <p>&#8377;${purchaseOrder?.subtotal}</p>
    <p>&#8377; ${aggregateTaxes(purchaseOrder.productId) && aggregateTaxes(purchaseOrder.productId)[0] && aggregateTaxes(purchaseOrder.productId)[0].taxCalculateValue
          }</p>
    <p>- &#8377; ${purchaseOrder?.discountAmount}</p>
    <p>${purchaseOrder.adjustmentType == 1 ? '+' : '-'} &#8377; ${purchaseOrder?.adjustment}</p>
  </div>
            </div >
            <hr style="border:1px solid #000" />
            <div class="to-pay">
              <p>Payable Amount</p>
              <p>&#8377; ${purchaseOrder?.payableAmount}</p>
            </div>
          </div >
        </div >
      </div >
  <div style="margin-top: 50px;display: flex;flex-direction: column;gap: 10px;">
    <p>* This is computer generated document, does not require any signature.</p>
  </div>
    </div >
  </div >
</body >

</html >
  ` }} />
    </>
  )
}

export default PBillsDetails