import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import { Button, Input, Select } from '../../components/Common'
import { appintmentTypeList } from '../../helper/Helper'

const AddAppointment = ({ handleClose, modalOpen, formik, data }) => {

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Add Appointment
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
          <Grid item xs={12}>
            <Select
              labelinput='Select User'
              selectHead='None'
              name='user'
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue('user', e.target.value);
              }}
              selectList={data}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              labelinput='Select Type'
              selectHead='None'
              name='type'
              formik={formik}
              onChange={(e) => {
                console.log("tytewyetwye", e.target.value);
                formik.setFieldValue('type', e.target.value);
              }}
              selectList={appintmentTypeList}
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Date' type='date' formik={formik} name='date' isDate={true} />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Time' type='time' formik={formik} name='time' isDate={true} />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Message' type='time' multiline rows='2' formik={formik} name='message' />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
        <Button buttonName='Save' size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  )
}

export default AddAppointment