import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'

const PaymentModal = ({ handleClose, data, formik, modalOpen, isLoading }) => {

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Add Payment
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>

          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Select
              labelinput="Payment Type"
              selectHead="None"
              name='paymentType'
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue('transactionId', "");
                formik.setFieldValue('chequeNumber', "");
                formik.setFieldValue('paymentType', e.target.value);
              }}
              selectList={[{
                id: 'Cash',
                name: 'Cash'
              }, {
                id: 'Online',
                name: 'Online'
              }, {
                id: 'Cheque',
                name: 'Cheque'
              }]}
            />)}
          </Grid>

          {formik.values.paymentType == "Online" && (

            <Grid item xs={12} sm={12}>

              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Transaction Id'
                name='transactionId'
                maxLength={10}
                formik={formik}
              />)}
            </Grid>
          )}
          {formik.values.paymentType == "Cheque" && (

            <Grid item xs={12} sm={12}>

              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Cheque Number'
                name='chequeNumber'
                maxLength={10}
                formik={formik}
              />)}
            </Grid>
          )}
          {formik.values.paymentType != "Cash" && (
            <>
              <Grid item xs={12} sm={12}>

                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Bank Name'
                  name='bankName'
                  maxLength={200}
                  formik={formik}
                />)}
              </Grid>
              <Grid item xs={12} sm={12}>

                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Account Number'
                  name='accountNumber'
                  maxLength={16}
                  formik={formik}
                />)}
              </Grid>
            </>

          )}

          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
              <Input labelinput='Payment Date'
                name='paymentDate'
                formik={formik}
                type='date'
                isDate={true}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Payment Amount'
              name='paymentAmount'
              maxLength={15}
              formik={formik}
            />)}
          </Grid>



        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'}
          size='small' onClick={formik.handleSubmit} disabled={isLoading} />
      </DialogActions>
    </>
  )
}

export default PaymentModal