import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...props }) => {

  const token = localStorage.getItem("token");

  return token ? children : <Navigate to="/" />;
};

export default PrivateRoute;
