import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'

const UomAdd = ({ handleClose, formik, modalOpen, isLoading }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Create'} {'UOM'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>
                    <Grid item xs={12} sm={12}>

                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Name'}
                            name='name'
                            formik={formik}
                            maxLength={50}
                        />)}

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'}
                    size='small' onClick={formik.handleSubmit} disabled={isLoading} />
            </DialogActions>
        </>
    )
}

export default UomAdd