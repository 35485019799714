import React, { useEffect, useState } from 'react'
import { DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { contactusDetail } from '../../../Service/ContactUs.service'
import { notificationSuccess } from '../../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'

const ContactUsDetail = ({ handleClose, formik, modalOpen }) => {
  const [constactData, setContactUsData] = useState();
  const dispatch = useDispatch();
  
   useEffect(() => {
      getContactUsDetails(modalOpen?.id);
    }, [modalOpen?.id]);
  

    const getContactUsDetails = async (id, type) => {
        let res = await contactusDetail(id);
        if (res?.status) {
            setContactUsData(res.data);
        } else {
            dispatch(notificationSuccess({
                show: true,
                type: 'error',
                message: res.message || 'Something went wrong Please try again later'
            }))
        }
    }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Contact Us Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2}   width={{ sm: '500px' }} paddingY={1}>

           <Stack flexDirection={'row'} width={"100%"}>
              <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'100px'}>
                Name
              </Typography>
              <Typography fontWeight={500} fontSize={'18px'}>
                : {constactData?.name}
              </Typography>
          </Stack>
          
          <Stack flexDirection={'row'} width={"100%"}>
              <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'100px'}>
                Email
              </Typography>
              <Typography fontWeight={500} fontSize={'18px'}>
                : {constactData?.email}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} width={"100%"}>
              <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'100px'}>
                Phone No.
              </Typography>
              <Typography fontWeight={500} fontSize={'18px'}>
                : {constactData?.phoneNo}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} width={"100%"}>
            <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'100px'}>
              Subject
            </Typography>
            <Typography fontWeight={500} fontSize={'18px'}>
              : {constactData?.subject}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} width={"100%"}>
            <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'100px'}>
              Message
            </Typography>
            <Typography fontWeight={500} fontSize={'18px'}>
              : {constactData?.message}
            </Typography>
          </Stack>
          
        </Grid>
      </DialogContent>
    
    </>
  )
}

export default ContactUsDetail