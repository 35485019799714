import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Switch } from '@mui/material'
import { Button, Heading, Modal } from '../../components/Common'
import { Pagination, Search } from '../../components/Common/Table'
import { ContactusList,contactusDetail } from '../../Service/ContactUs.service.js'
import moment from 'moment'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import { VscFilter } from 'react-icons/vsc'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { filterAppointmentInitialValues, filterContactInitialValues, filterTaxInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import ContactUsFilter from './ContactUsFilter'

const ContactUsList = () => {
    const dispatch = useDispatch();

    const [contactUsListData, setContactUsListData] = useState([]);
    const [search, setSearch] = useState('');
    const [totalData, setTotalData] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [maxCharacters, setMaxCharacters] = useState(20); // Default maximum characters
    const [value, setValue] = React.useState([]);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    useEffect(() => {
      getContactUsTList();
    }, [search, page, rowsPerPage]);
  

    const getContactUsTList = async () => {
        setContactUsListData([]);
        let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}`;
        let res = await ContactusList(query);
        if (res?.status) {
            setContactUsListData(res?.data);
            setTotalData(res?.total);
        } else {
            setContactUsListData(null);
        }
    }

    const formik = useFormik({
    initialValues: filterContactInitialValues,
        onSubmit: async (value) => {
        setOpenDrawer(false)
        getContactUsTList()
        },
    });
    console.log('formik----', formik);
    return (
        <div>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading head='Contact Us' />
            </Stack>
            <Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Search search={search} setSearch={setSearch} setPage={setPage} />
                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    {/* <Button onClick={toggleDrawer} buttonName={<VscFilter />} style={{ padding: '10px 11px' }} color='white' size='small' />
                    <ContactUsFilter
                        value={value}
                        setValue={setValue}
                        formik={formik}
                        openDrawer={openDrawer} toggleDrawer={toggleDrawer} /> */}
                </Stack>
            </Stack>
            <TableContainer component={Paper} className='tablebodyscrooling'>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell style={{ cursor: 'pointer' }} > {"Name"} </TableCell>
                            <TableCell style={{ cursor: 'pointer' }} > {"Email"} </TableCell>
                            <TableCell style={{ cursor: 'pointer' }} > {"Phone no."} </TableCell>
                            <TableCell style={{ cursor: 'pointer' }} >{"CreatedDate"}
                            </TableCell>
                            {/* <TableCell>{"Actions"}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactUsListData && contactUsListData.length > 0 && contactUsListData?.map((data, i) => {
                            return (
                              <TableRow key={i} onClick={() => {
                                  setIsModalOpen({ open: true, currentComponent: "contactusview" ,id: data.id });
                                }}>
                                <TableCell className='table-hover' title={data && data?.name}>{data?.name}</TableCell>
                                <TableCell>{data?.email}</TableCell>
                                <TableCell>{data?.phoneNo}</TableCell>
                                <TableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                              </TableRow>
                            )
                        })}

                        {contactUsListData === null ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        color: "silver",
                                        textAlign: "center",
                                        paddingTop: "90px",
                                        borderBottom: "none",
                                        fontSize: "30px",
                                    }}
                                    colSpan="3"
                                >
                                    {"No records to display"}
                                </TableCell>
                            </TableRow>

                        ) : (
                            contactUsListData && contactUsListData.length === 0 ? (<TableRowsLoader colNumber={3} rowsNum={10} />) : ("")
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {
                // Conditionally render Pagination component if contactUsListData is not null
                contactUsListData != null && (
                    <Pagination
                        totalData={totalData} // Total number of data items
                        page={page} // Current page number
                        setPage={setPage} // Function to update current page number
                        rowsPerPage={rowsPerPage} // Number of rows per page
                        setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
                    />
                )
            }
        <Modal
          modalOpen={isModalOpen}
          isLoading={isLoading}
          formik={formik}
          setIsLoading={setIsLoading}
          handleClose={() => setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' })}
          id={isModalOpen.id}
          currentComponent={isModalOpen.currentComponent}
          
        />
      </div >
    )
}

export default ContactUsList