import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Switch, Chip } from '@mui/material'
import { Button, Heading, MenuButton, Modal } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import { Pagination, Search } from '../../components/Common/Table'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'
import { purchaseOrderListData, purchaseOrderPayment } from '../../Service/Purchase.service'
import moment from 'moment'
import { VscFilter } from 'react-icons/vsc'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { filterPurchaseOrderInitialValues, paymentPurchaseOrderInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { purchaseOrderPaymentValidationSchema } from '../../helper/validation'
import PurchaseOrderFilter from '../PurchaseOrder/PurchaseOrderFilter'


const PurchaseBills = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('category');
  const [sortBy, setSortBy] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getPurchaseList = async () => {
    setPurchaseOrderData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await purchaseOrderListData(query);
    if (res?.status) {
      setPurchaseOrderData(res?.data);
      formik.setFieldValue('formPrice', res?.minPrice);
      formik.setFieldValue('toPrice', res?.maxPrice);
      value.length === 0 &&
        setValue([res?.minPrice, res?.maxPrice]);

      setTotalData(res?.total);
    } else {
      setPurchaseOrderData(null);
    }
  }


  const formik = useFormik({
    initialValues: filterPurchaseOrderInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getPurchaseList();
    },
  });
  const formik1 = useFormik({
    initialValues: paymentPurchaseOrderInitialValues,
    onSubmit: async (value) => {
      const payload = {
        "orderId": isModalOpen.id,
        "paymentType": value.paymentType,
        "transactionId": value.transactionId,
        "checkNo": value.checkNo,
        "paymentAmount": value.paymentAmount,
        "bankName": value.bankName,
        "paymentDate": value.paymentDate,
        "accountNumber": value.accountNumber,
      }

      const res = await purchaseOrderPayment(payload);

      console.log(res)

      if (res?.status) {
        setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        dispatch(notificationSuccess({
          message: res?.message || 'Payment Done Successfully',
          show: true,
          type: 'success',
        }))

        getPurchaseList();
      } else {
        dispatch(notificationSuccess({
          message: res?.message || 'Payment Failed',
          show: true,
          type: 'error',

        }))
      }

    },
    validationSchema: purchaseOrderPaymentValidationSchema
  });

  useEffect(() => {
    getPurchaseList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'purchaseDate':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('purchaseDate')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('purchaseDate')
        break;
    }
  };


  return (
    <div>

      <TableContainer component={Paper} className='tablebodyscrooling'>
        <Stack p={'12px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
            {/* <Button buttonName='Purchase Order' startIcon={<IoAdd />} size='small' onClick={() => navigate('/create-purchase-order')} /> */}
            <Button buttonName='Filter' onClick={toggleDrawer} startIcon={<VscFilter />} color='white' size='small' />
            <PurchaseOrderFilter openDrawer={openDrawer} toggleDrawer={toggleDrawer}
              formik={formik}
            />
          </Stack>
        </Stack>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell style={{ cursor: 'pointer' }} > Order No. </TableCell>
              <TableCell style={{ cursor: 'pointer' }} > Vendor Name </TableCell>
              <TableCell style={{ cursor: 'pointer' }} > Discount </TableCell>
              <TableCell style={{ cursor: 'pointer' }} > Tax </TableCell>
              <TableCell style={{ cursor: 'pointer' }} >Payable Amount</TableCell>
              <TableCell style={{ cursor: 'pointer' }} >Payment Status</TableCell>
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('purchaseDate', sortDate)}>Purchase Date
                {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrderData && purchaseOrderData.length > 0 && purchaseOrderData?.map((data, i) => {
              return (
                <TableRow 
                      key={i} 
                      onClick={() => navigate('/view-purchase-bill', { state: { id: data.id }})}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                    >

                  <TableCell className='table-hover' >{data?.orderNumber}</TableCell>
                  <TableCell className='table-hover' >{data?.vendername}</TableCell>
                  <TableCell className='table-hover' >{data?.discountAmount == "" ? 0 : data?.discountAmount}</TableCell>
                  <TableCell className='table-hover' >{data?.tax}</TableCell>
                  <TableCell className='table-hover' >{data?.payableAmount}</TableCell>
                  <TableCell className='table-hover' >{data?.paymentStatus == 1 ?
                    <Chip label="Paid" color="success" /> : <Chip label="Pending" color="warning" />}</TableCell>
                  <TableCell>{moment(data?.purchaseDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className='chatDropDown'>
                        <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '24px' }} />} width='35px' height='38px' />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className='chatDropDownContent' align='end'>
                        <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/view-purchase-bill', { state: { id: data.id }})}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>View</Box></Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              )
            })}

            {purchaseOrderData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              purchaseOrderData && purchaseOrderData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        // Conditionally render Pagination component if purchaseOrderData is not null
        purchaseOrderData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }
      <Modal
        modalOpen={isModalOpen} // Indicates whether the modal is open or closed
        // handelClick={changeStatus} // Handler function to change the status of the modal
        handleClose={() => {
          // Handler function to close the modal and reset its state
          formik1.resetForm();
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        }
        }
        formik={formik1}
      />
    </div >
  )
}

export default PurchaseBills