import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button } from "../../Common";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import CommonSelect from "../Select";

const AddGroup = ({ handleClose, formik, data, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit" : "Create"} Group
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} minWidth={{xs:"250px", sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Select Transporter</Typography>
            <CommonSelect
              name="transportId"
              formik={formik}
              selectHead="None"
              value={formik.values.transportId}
              onChange={(e) => {
                formik.setFieldValue("transportId", e.target.value);
              }}
              selectList={data}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName={"Save"}
          size="small"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddGroup;
