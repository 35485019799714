import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../../helper/Icons'
import { Autocomplete, Button, Input } from '../../../../components/Common'

const CityAdd = ({ handleClose, modalOpen, formik, data, stateList }) => {
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {isEdit ? 'Edit' : isView ? 'View' : 'Add'} City
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '500px' }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Autocomplete
              data={data}
              labelinput='Country'
              onChange={(e, val) => {
                if (val) {
                  formik.setFieldValue('countryId', val.id);
                  formik.setFieldValue('country', val);
                  formik.touched.countryId = false;
                } else {
                  formik.setFieldValue('countryId', "");
                  formik.setFieldValue('country', '');
                }
              }}
              name='countryId'
              name1='country'
              formik={formik}
              value={formik.values.country}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              data={stateList}
              labelinput='State'
              onChange={(e, val) => {
                if (val) {
                  formik.setFieldValue('stateId', val.id);
                  formik.setFieldValue('state', val);
                  formik.touched.countryId = false;
                } else {
                  formik.setFieldValue('stateId', "");
                  formik.setFieldValue('state', '');
                }
              }}
              name='stateId'
              name1='state'
              formik={formik}
              value={formik.values.state}
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='City Name' disabled={isView}
              formik={formik}
              name='cityName'
              maxLength={50}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isView ? '' :
          <>
            <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
            <Button buttonName={`${isEdit ? 'Save' : 'Add'}`} size='small' onClick={formik.handleSubmit} />
          </>
        }
      </DialogActions>
    </>
  )
}

export default CityAdd