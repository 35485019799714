import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import Multiselect from "multiselect-react-dropdown";

const AddDiscount = ({ handleClose, formik, removeUser, userData, selectUser, multiselectSelectUser, modalOpen, categoryData, files, setFiles }) => {
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {isEdit ? 'Edit' : isView ? 'View' : 'Add'} Discount Group
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '500px' }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Typography fontSize={{ xs: '13px', sm: '16px' }} fontWeight={500} mb={'2px'}>Users</Typography>

            <Multiselect
              options={userData} // Options to display in the dropdown
              selectedValues={selectUser} // Preselected value to persist in dropdown
              onSelect={multiselectSelectUser} // Function will trigger on select event
              onRemove={removeUser} // Function will trigger on remove event
              // displayValue={(option) => (option ? option.name : "")} // Property name to display in the dropdown options
              displayValue="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Title'
              formik={formik}
              name='title'
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Value'
              formik={formik}
              name='value'
              maxLength={50}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isView ? '' :
          <>
            <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
            <Button buttonName={`${isEdit ? 'Save' : 'Add'}`} size='small' onClick={formik.handleSubmit} />
          </>
        }
      </DialogActions>
    </>
  )
}

export default AddDiscount