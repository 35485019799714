import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input, Select } from '../../../components/Common'

const AddAdmins = ({ handleClose, formik, data, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit' : 'Create'}  Admin
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: '900px' }} paddingY={1}>
          <Grid item xs={12} sm={6}>
            <Input labelinput='First Name'
              name='firstName'
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput='Last Name'
              name='lastName'
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput='Email'
              name='email'
              formik={formik}
              disabled={modalOpen.isEdit}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              labelinput='Role'
              name='role'
              selectHead='None'
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue('role', e.target.value);
              }}
              selectList={data}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Input labelinput='Address' multiline rows={2} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput='Password' passwordinput />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput='Confirm Password' passwordinput />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'} size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  )
}

export default AddAdmins