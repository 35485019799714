import React, { useEffect, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { BackButton, Heading } from '../../components/Common'
import { Box } from '@mui/system'
import { getAppoinmentDetails } from '../../Service/Appointment.service'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const AppointmentDetails = () => {
  const [appointmentData, setAppointmentData] = useState({})
  const { state } = useLocation()

  const getAppoinmentData = async (id) => {
    const res = await getAppoinmentDetails(id)

    if (res.status) {
      setAppointmentData(res.data)
    } else {
      setAppointmentData({})
    }
  }

  useEffect(() => {
    if (state && state?.id) {
      getAppoinmentData(state.id)
    }
  }, [state])

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head='Appointment Details' />
      </Stack>

      <Grid container spacing={2}>
        {/* for Bulk Ordering */}
        {appointmentData.type == 1 && (
          <Grid item xs={12}>
            <Box className='card_container' p={4}>
              <Stack gap={1}>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Full Name
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.fullName}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Phone Number
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.phoneNo}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Email Address
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.email}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Company Name
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.companyName}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Position within the Company
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.position}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Product Category(s) of Interest
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.productCategory}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Estimated Quantity of Materials
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.estimatedQuantity}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Specific SKUs (if known)
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData && appointmentData.skus && Array.isArray(JSON.parse(appointmentData.skus))
                      ? JSON.parse(appointmentData.skus).join(', ')
                      : ''}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Project Type
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    :{appointmentData.projectType}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Delivery PIN Code
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.deliveryPinCode}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Expedited Shipping
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.expeditedShipping == 1 ? 'Yes' : 'No'}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Specific Brand Preference
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.brandPreference}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Delivery Timeline
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {moment(appointmentData.deliveryTimeline).format('DD-MM-YYYY')}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Indicative Budget
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.indicativeBudget}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Notification Preferences
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.notificationPreference}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Meeting Date
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {moment(appointmentData.date).format("DD-MM-YYYY")}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Meeting Time
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.time}
                  </Typography>
                </Stack>
                <Stack flexDirection={'row'}>
                  <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                    Questions/Comments
                  </Typography>
                  <Typography fontWeight={500} fontSize={'18px'}>
                    : {appointmentData.questionsComments}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        )}


        {/* for Expert Consultation */}
        {appointmentData.type == 2 && (<Grid item xs={12}>
          <Box className='card_container' p={4}>
            <Stack gap={1}>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Full Name
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.fullName}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Phone Number
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.phoneNo}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Email Address
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.email}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Project Type
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  :
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Scope of Consultation
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.scopeOfConsultation}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Previous Experience with Construction Projects
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.previousExperience}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Type of Materials Interested In
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.materialType}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Sustainability and Eco-friendliness
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.sustainability}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Architectural Plans or Drawings
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  :{appointmentData.architecturalPlans}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Budget
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  :{appointmentData.estimatedBudget}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Financing Needs
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.financingNeeded}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Notification Preferences
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.notificationPreference}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Meeting Date
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {moment(appointmentData.date).format("DD-MM-YYYY")}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Meeting Time
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.time}
                </Typography>
              </Stack>
              <Stack flexDirection={'row'}>
                <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'450px'}>
                  Additional Notes
                </Typography>
                <Typography fontWeight={500} fontSize={'18px'}>
                  : {appointmentData.additionalnotes}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>)}

      </Grid>
    </>
  )
}

export default AppointmentDetails