import React from "react";
import {
  Drawer,
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
  Slider,
  Box,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, Input, Select } from "../../components/Common";
import { saleOrderStatus, statusFilter } from "../../helper/Helper";

const SalesOrderFilter = ({ openDrawer, setValue, toggleDrawer, formik }) => {
  const drawerWidth = 250;

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            padding: 2,
          },
        }}
      >
        <Box height={"100%"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={"20px"}>
                Filter
              </Typography>
              <IconButton
                aria-label="close"
                onClick={toggleDrawer}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} marginY={"5px"}>
              <Divider sx={{ borderColor: "gray.main" }} />
            </Grid>

            <Grid item xs={12}>
              {/* <Grid item xs={12}>
                <Select
                  labelinput="Status"
                  selectHead="None"
                  name="status"
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue("status", e.target.value);
                  }}
                  selectList={statusFilter}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Select
                  labelinput="order Status"
                  name="orderStatus"
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue("orderStatus", e.target.value);
                  }}
                  selectList={saleOrderStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Stack flexDirection={"row"} gap={1}>
          <Button
            buttonName="Clear"
            color="white"
            style={{ width: "100%" }}
            size="small"
            onClick={() => {
              formik.resetForm();
              setValue([]);
              toggleDrawer();
              formik.handleSubmit();
            }}
          />
          <Button
            buttonName="Filter"
            style={{ width: "100%" }}
            size="small"
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Drawer>
    </>
  );
};

export default SalesOrderFilter;
