import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload } from '../../../components/Common'
import ExcelUpload from '../../../components/Common/ExcelUpload'

const UploadProduct = ({ handleClose, formik, data, modalOpen, handelClick, files, setFiles }) => {

  const downloadSampleCSV = () => {
    const csvContent = [
      ['Category', 'SubCategory', 'Brand', 'Name', 'Price', 'MRP', 'Description', 'Warehouse'],
      ['Sample Category', 'Sample SubCategory', 'Sample Brand', 'Sample Name', '0', '0', 'Sample Description', 'Sample Warehouse']
    ];

    let csvString = csvContent.map(row => row.join(',')).join('\n');
    let blob = new Blob([csvString], { type: 'text/csv' });
    let url = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = url;
    a.download = 'sample_product_upload.csv';
    a.click();
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Upload Product
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* <DialogActions sx={{display:"flex",justifyContent:"flex-start"}}>
        <Button buttonName='Download Sample CSV' size='small' onClick={downloadSampleCSV}/>
      </DialogActions> */}
      <DialogContent sx={{  width: '500px' }}>
        <Grid container spacing={2} maxWidth={{ sm: '1200px' }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <ExcelUpload files={files} setFiles={setFiles} singleImage={true}
              accept=".xls,.xlsx,.csv" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
      <Button buttonName='Download Sample CSV' size='small' onClick={downloadSampleCSV}/>
      <Box sx={{display:"flex",gap:"5px"}}>
        <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
        <Button buttonName='Upload' size='small' onClick={handelClick} />
      </Box>
      </DialogActions>
    </>
  )
}

export default UploadProduct
