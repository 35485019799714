import React, { useEffect, useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CreateDiscount, DiscountDetail, DiscountList, DiscountUpdate } from '../../../Service/Discount.service';
import { BackButton, Button, FileUpload, Heading, Input, Select } from '../../../components/Common'
import { addDiscountValidationSchema } from '../../../helper/validation';
import { useFormik } from 'formik';
import { addDiscountInitialValues } from '../../../helper/initialValues';
import { notificationSuccess } from '../../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import Multiselect from "multiselect-react-dropdown";
import { userDropdownDiscount } from '../../../Service/User.service';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DiscountAdd = () => {
  const [deleteSkill, setDeleteUser] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [userData, setUserData] = useState([]);
  const [Id, setId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: addDiscountInitialValues,
    onSubmit: async (value) => {
      const payload = {
        "title": value.title,
        "value": value.value,
        "deletedUserIds": deleteSkill,
        "users": selectUser
      }

      let res = isEdit ? await DiscountUpdate(Id, payload) : await CreateDiscount(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        formik.resetForm();
        navigate('/coupon', {
          state: { discount: true }
        })
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }
        ))
      }
    },
    validationSchema: addDiscountValidationSchema,
  });
  useEffect(() => {
    getSkillList();
  }, []);

  useEffect(() => {
    if (state && state.id) {
      setId(state.id);
      setIsEdit(true);
      getDiscountDetailData(state.id);
    }
  }, [state]);

  const getDiscountDetailData = async (id) => {
    const res = await DiscountDetail(id);
    if (res && res.data) {
      formik.setFieldValue('title', res.data?.title);
      formik.setFieldValue('value', res.data?.value);
      const users = res && res.data && res.data.userData && res.data.userData.map((item) => {
        return { userId: item.userId, id: item.id, name: item.name };
      });
      setSelectUser(res && res.data && res.data.userData && res.data.userData.length > 0 ? users : []);
    }
  }


  const getSkillList = async () => {
    const res = await userDropdownDiscount();
    if (res && res.data) {
      const users = res.data.map((item) => {
        return { userId: item.id, name: item.name };
      });
      setUserData(users);
    }
  };
  const multiselectSelectUser = (data, e) => {

    console.log('dataeeeeeeeeeeeeeeeeeee', e);
    setSelectUser([...selectUser, {
      userId: e.userId,
      name: e.name,
      // fresherId: Id,
    }]);
    setUserData(userData.filter((item) => { return !data.some((item2) => { return item.userId === item2.userId }) }));
  };

  console.log(selectUser, 'selectUser');
  //remove the skill
  const removeUser = async (e, item) => {
    setUserData([...userData, item]);
    const removeUser = selectUser.filter(
      (items) => items.userId != item.userId
    );
    setSelectUser(removeUser);
    if (item && item.id) {
      setDeleteUser([...deleteSkill, item?.id]);
    }
  };
  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <ArrowBackIcon
          onClick={() => navigate('/coupon', {
            state: { discount: true }
          })}
          style={{ cursor: "pointer", background: "#7345B3", color: "#fff", borderRadius: '5px', padding: '4px', fontSize: '28px' }}
        />
        <Heading head={`${isEdit ? 'Edit' : 'Create'} Discount`} />

      </Stack>
      <Box className='card_container' p={4}>
        <Grid container spacing={3}>
          <Box className='border_card' p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontSize={{ xs: '13px', sm: '16px' }} fontWeight={500} mb={'2px'}>Users</Typography>

                <Multiselect
                  options={userData} // Options to display in the dropdown
                  selectedValues={selectUser} // Preselected value to persist in dropdown
                  onSelect={multiselectSelectUser} // Function will trigger on select event
                  onRemove={removeUser} // Function will trigger on remove event
                  // displayValue={(option) => (option ? option.name : "")} // Property name to display in the dropdown options
                  displayValue="name"
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput='Title'
                  formik={formik}
                  name='title'
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput='Value ( % )'
                  formik={formik}
                  name='value'
                  maxLength={2}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Stack flexDirection={'row'} gap={1} mt={3} justifyContent={'flex-end'}>
          <Button buttonName='Discard' color='white' onClick={() => {
            navigate('/coupon', {
              state: { discount: true }
            })
          }} />
          <Button buttonName={`${isEdit?.isEdit ? 'Update' : 'Create'}`}
            onClick={formik.handleSubmit}
            color='primary' />
        </Stack>
      </Box>
    </>
  )
}

export default DiscountAdd