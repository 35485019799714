import { get, post, put } from "../web.request";

export const getAppoinmentDetails = async (id) => {
  return await get(`/appointmentDetail/${id}`);
}

export const getAppointmentList = async (query) => {
  return await get(`/appointmentList${query}`);
}

export const AppointmentStatusChange = async (id, data) => {
  return await put(`/statusChangeAppointment/${id}`, data);
}

//timeDropdown
export const getTimeDropdown = async () => {
  return await get(`/timeDropdown`);
}

export const MessageDropdown = async () => {
  return await get(`/messageDropdown`);
}

export const SlotDetail = async () => {
  return await get(`/timeSlotDetail/1`);
}

export const SlotUpdate = async (data) => {
  return await post(`/createTimeSlot`, data);
}