import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'

const DeliveryRateAdd = ({ handleClose, formik, modalOpen, isLoading }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Create'} {'Trasportation Rate'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>
                    <Grid item xs={12} sm={12}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Vehicle Name'}
                            name='vehicleName'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Per KM Charge'}
                            name='perKmCharge'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Handling Charge'}
                            name='handlingCharge'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Per KG Charge'}
                            name='perKgPrice'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Per Unit Charge'}
                            name='perUnitPrice'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Min. Quantity'}
                            name='minQuantity'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={'Max. Quantity'}
                            name='maxQuantity'
                            formik={formik}
                            maxLength={50}
                        />)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'}
                    size='small' onClick={formik.handleSubmit} disabled={isLoading} />
            </DialogActions>
        </>
    )
}

export default DeliveryRateAdd