import { deleteRequest, get, post, put } from "../web.request";

export const CreateDiscount = async (data) => {
  return await post(`/createDiscount`, data);
}

export const DiscountList = async (query) => {
  return await get(`/discountList${query}`)
}

export const DiscountDetail = async (id) => {
  return await get(`/discountDetail/${id}`);
}

export const DiscountUpdate = async (id, data) => {
  return await put(`/updateDiscount/${id}`, data);
}

export const DiscountDelete = async (id) => {
  return await deleteRequest(`/deleteDiscount/${id}`);
}
