import React, { useEffect, useState } from 'react'
import { Button, Heading, MenuButton, Modal, Select } from '../../components/Common'
import { Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'
import { Pagination, Search } from '../../components/Common/Table'
import { useNavigate } from 'react-router-dom'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import AppoinmentFilter from './AppoinmentFilter'
import { VscFilter } from 'react-icons/vsc'
import { filterAppointmentInitialValues, statusAppointmentInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { userDropdown } from '../../Service/User.service'
import { getAppointmentList, AppointmentStatusChange, getTimeDropdown, MessageDropdown } from '../../Service/Appointment.service'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import moment from 'moment'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'

export const Appointment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const appType = false;
  const appStatus = false;
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortBy, setSortBy] = useState(false);
  const [shortKey, setShortKey] = useState('name');
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(30); // Default maximum characters
  const [timeData, setTimeData] = useState([])
  const [messageData, setMessageData] = useState([])


  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getTimeDropdownData = async () => {
    const res = await getTimeDropdown();
    if (res?.status) {
      setTimeData(res.data)
    } else {
      setTimeData([])
    }
  }

  useEffect(() => {
    getTimeDropdownData()
    getMessageDropdown()
  }, [])

  const getMessageDropdown = async () => {
    const res = await MessageDropdown();
    if (res?.status) {
      let data = res.data.map((item) => {
        return {
          id: item.message,
          name: item.message
        }
      })
      setMessageData(data)
    } else {
      setMessageData([])

    }
  }

  const formik = useFormik({
    initialValues: filterAppointmentInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false)
      getAppointMentData()
    },
  });
  const formik1 = useFormik({
    initialValues: statusAppointmentInitialValues,
    onSubmit: async (value) => {
      StatusChange()

    },
  });

  const getAppointMentData = async () => {
    let query = `?search=${search}&status=${formik.values.status}&page=${page + 1}&perPageSize=${rowsPerPage}&sortFlag=${sortBy}&orderBy=${shortKey}&type=${formik.values.type}&date=${formik.values.date}&time=${formik.values.time}`

    const res = await getAppointmentList(query);
    if (res?.status) {
      setAppointmentData(res.data)
      setTotalData(res.total)
    } else {
      setAppointmentData([])
    }
  }
  useEffect(() => {
    getAppointMentData();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  useEffect(() => {
    // Update max characters based on screen width
    const updateMaxCharacters = () => {
      if (window.innerWidth < 768) {
        setMaxCharacters(30); // Set max characters for smaller screens
      } else {
        setMaxCharacters(40); // Set max characters for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxCharacters);

    // Initial update
    updateMaxCharacters();

    // Cleanup
    return () => window.removeEventListener('resize', updateMaxCharacters);
  }, []);

  const StatusChange = async () => {
    const payload = {
      status: isModalOpen.status1,
      note: formik1.values.note,
      message: formik1.values.message,
    }

    const res = await AppointmentStatusChange(isModalOpen.id, payload);
    if (res?.status) {
      getAppointMentData()
      setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))

    }

  }


  const truncateText = (text, maxLength) => {
    return text && text != "" ? text.length > maxLength ? text.slice(0, maxLength) + '...' : text : "-"
  };

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'date':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('name')
        break;
    }
  };


  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Heading head='Appointment' />
      </Stack>
      <TableContainer component={Paper} TableContainer >
        <Stack p={'12px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          {/* <Button startIcon={<IoAdd style={{ color: '#fff' }} />} buttonName='Appointment' size='small' onClick={() => setIsModalOpen({ open: true, currentComponent: "addAppointment" })} /> */}
          <Button buttonName='Filter' onClick={toggleDrawer} startIcon={<VscFilter />} color='white' size='small' />
          <AppoinmentFilter openDrawer={openDrawer} toggleDrawer={toggleDrawer}
            formik={formik} timeData={timeData}
          />
        </Stack>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Email</TableCell>

              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('date', sortDate)}>Date
                {sortDate ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentData && appointmentData.length > 0 && appointmentData.map((data, index) => (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }} onClick={() => navigate('/view-appointment', {
                state: {
                  id: data.id
                }
              })} key={index}>

                <TableCell className='table-hover' title={data && data?.fullName}>{truncateText(data?.fullName, maxCharacters)}</TableCell>
                <TableCell>
                  {data && data.type == 1 ?
                    <Chip label='Bulk Ordering' color="primary" />
                    : <Chip label='Consultation' color='secondary' />
                  }
                </TableCell>
                <TableCell className='table-hover' title={data.email}>{truncateText(data?.email, maxCharacters)}</TableCell>
                <TableCell>{data && data.date ? moment(data.date).format('DD-MM-YYYY') : "-"}</TableCell>
                <TableCell>{data && data.time ? data.time : "-"}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  {data && data.status == 1 ?
                    (<Select
                      name='status'
                      value={data.status}
                      onChange={(e) => {
                        setIsModalOpen({
                          id: data?.id, status1: e.target.value, status: e.target.value == 1 ? 'Booked' : e.target.value == 2 ? 'Close' : e.target.value == 3 ? 'Cancel' : 'Complete',
                          open: true, currentComponent: "ApoinmentstatusChange"
                        })
                      }}
                      selectList={[{
                        id: 1,
                        name: 'Booked'
                      },
                      {
                        id: 2,
                        name: 'Close'
                      }, {
                        id: 3,
                        name: 'Cancel'
                      }, {
                        id: 4,
                        name: 'Complete'
                      }]}
                    />) : data && data.status == 3 ? <Chip label='Cancelled' color='error'
                    /> : data && data.status == 2 ? <Chip label='Close' color='warning'
                    /> : <Chip label='Complete' color='success' />
                  }

                </TableCell>
                <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger className='chatDropDown'>
                      <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '24px' }} />} width='35px' height='38px' />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content className='chatDropDownContent' align='end'>
                      <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/view-appointment', {
                        state: {
                          id: data.id
                        }
                      })}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>View</Box></Stack>
                      </DropdownMenu.Item>
                      {/* <DropdownMenu.Item className='chatDropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addAppointment" })} >
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>View</Box></Stack>
                      </DropdownMenu.Item> */}
                      {/* <DropdownMenu.Item className='chatDropDownMenu' onClick={() =>
                        setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Appointment", para: "Are you sure you want to Delete this Appointment?" })}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>Delete</Box></Stack>
                      </DropdownMenu.Item> */}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </TableCell>
              </TableRow>
            ))}

            {appointmentData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              appointmentData && appointmentData.length === 0 ? (<TableRowsLoader rowsNum={1} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        appointmentData != null && (
          <Pagination totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />)
      }

      <Modal
        modalOpen={isModalOpen}
        data={messageData}
        formik={formik1}
        handelClick={StatusChange}
        handleClose={() => {
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
          formik1.resetForm()
        }
        } />
    </div >
  )
}
