import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons';
import { Button, Input, Select } from '../../../components/Common';

const EditAddress = ({ handleClose, modalOpen, formik }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Edit Address
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '500px' }}>
        <Grid container spacing={2} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput='Address Line 1'
              name='addressLine1'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput='Address Line 2'
              name='addressLine2'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput='Landmark'
              name='landmark'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Zip Code'
              name='zipCode'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput='State'
              name='state'
              formik={formik}
              disabled={true} />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput='City'
              formik={formik}
              name='city'
              disabled={true}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
        <Button buttonName='Save' size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  )
}

export default EditAddress