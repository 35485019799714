import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Switch } from '@mui/material'
import { Button, MenuButton, Modal } from '../../components/Common'
import { Pagination, Search } from '../../components/Common/Table'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoAdd } from 'react-icons/io5'
import { AiOutlineEdit } from 'react-icons/ai'
import { HiOutlineTrash } from 'react-icons/hi'
import { VscFilter } from 'react-icons/vsc';
import { brandList, changeBrandStatus, deleteBrand, addBrand, updateBrand } from '../../Service/brand.service'
import moment from 'moment';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import { addCategoryInitialValues, filterValueInitialValues } from '../../helper/initialValues';
import { addCategoryValidationSchema } from '../../helper/validation';
// import FilterCategory from './FilterCategory';
import TableRowsLoader from '../../components/Common/Loader/Skeleton';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { IsDelete, IsWrite } from '../../helper/handlePermission';

const Brand = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [search, setSearch] = useState('');
  const [brandData, setBrandData] = useState([]);
  const editPermissions = IsWrite("category");
  const deletePermissions = IsDelete("category");
  const [totalData, setTotalData] = useState(0)
  const [files, setFiles] = useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortCategory, setSortCategory] = useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [sortBy, setSortBy] = useState(false);
  const [shortKey, setShortKey] = useState('name');
  const [maxCharacters, setMaxCharacters] = useState(30); // Default maximum characters

  useEffect(() => {
    // Update max characters based on screen width
    const updateMaxCharacters = () => {
      if (window.innerWidth < 768) {
        setMaxCharacters(30); // Set max characters for smaller screens
      } else {
        setMaxCharacters(40); // Set max characters for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxCharacters);

    // Initial update
    updateMaxCharacters();

    // Cleanup
    return () => window.removeEventListener('resize', updateMaxCharacters);
  }, []);


  const truncateText = (text, maxLength) => {
    return text && text != "" ? text.length > maxLength ? text.slice(0, maxLength) + '...' : text : "-"
  };

  const dispatch = useDispatch();

  const getCategoryData = async () => {
    setBrandData([]);
    let query = `?search=${search}&name=${formik1.values.categoryName}&status=${formik1.values.status}&page=${page + 1}&perPageSize=${rowsPerPage}&sortFlag=${sortBy}&orderBy=${shortKey}`;
    const res = await brandList(query);
    if (res.status) {
      setTotalData(res?.total);
      setBrandData(res.data);
    }
    else {
      setBrandData(null);
    }
  }

  const formik = useFormik({
    initialValues: addCategoryInitialValues,
    onSubmit: async (value) => {
      const formData = new FormData();
      if (files.length > 0) {
        formData.append('name', value.categoryName);
        if (files.length > 0 && !files[0]?.id) {
          formData.append('image', files[0]);
        }

        let res = isModalOpen?.isEdit ? await updateBrand(isModalOpen?.id, formData) : await addBrand(formData);
        if (res?.status) {
          dispatch(notificationSuccess({
            show: true,
            type: 'success',
            message: res.message || 'Success'
          }))
          getCategoryData();
          setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
          formik.resetForm();
          setFiles([]);
        } else {
          dispatch(notificationSuccess({
            show: true,
            type: 'error',
            message: res.message || 'Something went wrong. Please try again later.'
          }
          ))
        }
      }
      else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Please select an image.'
        }
        ))
      }
    },
    validationSchema: addCategoryValidationSchema,
  });


  const formik1 = useFormik({
    initialValues: filterValueInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getCategoryData();
    },
  });



  useEffect(() => {
    getCategoryData();
  }, [search, page, rowsPerPage, sortBy, shortKey]);
  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "status" ? await changeBrandStatus(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteBrand(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getCategoryData();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }
      ))
    }
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };


  const handleSortClick = (name, by) => {
    switch (name) {
      case 'name':
        setSortBy(!by)
        setSortCategory((current) => !current)
        setSortDate(false)
        setShortKey('name')
        break;
      case 'date':
        setSortBy(!by)
        setSortDate((current) => !current)
        setSortCategory(false)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortCategory)
        setBrandData((current) => !current)
        setSortDate(false)
        setShortKey('name')
        setSortCategory((current) => !current)
        break;
    }
  };



  return (
    <>
      <TableContainer component={Paper} className='tablebodyscrooling'>
        <Stack p={'12px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
            {editPermissions && (<Button buttonName='Brand' startIcon={<IoAdd />} size='small' onClick={() => setIsModalOpen({ open: true, currentComponent: "addBrand", isEdit: false })} />)}
          </Stack>
        </Stack>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow className='MuiReportTable-root'>
              <TableCell style={{ cursor: 'pointer' }}
                onClick={() => handleSortClick('name', sortCategory)} > Brand Name
                {sortCategory ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }

              </TableCell>
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('date', sortDate)}>Created Date
                {sortDate ?
                  <FaArrowUp style={{ color: '#adadad' }} />

                  :
                  <FaArrowDown style={{ color: '#adadad' }} />


                }
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brandData && brandData.length > 0 && brandData?.map((data, i) => {
              return (
                <TableRow key={i} className='MuiReportTable-root'
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell className='table-hover' title={data?.name}>
                    <img src={data?.image} alt={data?.name} style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: "15px" }} />
                    {truncateText(data?.name, maxCharacters)}
                  </TableCell>

                  <TableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell><Switch
                    checked={data?.status === 1 ? true : false}
                    onChange={(e) => {
                      if (editPermissions) {

                        e.stopPropagation();
                        setIsModalOpen({ id: data?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                      }
                      else {
                        dispatch(notificationSuccess({
                          show: true,
                          type: 'error',
                          message: 'You do not have permission to Change Status'
                        }))
                      }
                    }}
                  /></TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className='chatDropDown'>
                        <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '24px' }} />} width='35px' height='38px' />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className='chatDropDownContent' align='end'>

                        <DropdownMenu.Item className='chatDropDownMenu'>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => {
                            if (editPermissions) {
                              formik.setFieldValue('categoryName', data?.name);
                              setFiles([{ id: data?.id, image: data?.image }]);
                              setIsModalOpen({ open: true, currentComponent: "addBrand", isEdit: true, id: data?.id })
                            } else {
                              dispatch(notificationSuccess({
                                show: true,
                                type: 'error',
                                message: 'You do not have permission to Edit'
                              }))
                            }
                          }}
                          ><AiOutlineEdit style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>Edit</Box></Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='chatDropDownMenu' onClick={() => {

                          if (deletePermissions) {

                            setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Brand", para: "Are you sure you want to Delete this brand?", id: data?.id })
                          } else {
                            dispatch(notificationSuccess({
                              show: true,
                              type: 'error',
                              message: 'You do not have permission to Change Status'
                            }))
                          }
                        }
                        }>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>Delete</Box></Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              )
            })}

            {brandData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              brandData && brandData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {brandData != null && (
        <Pagination totalData={totalData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />)
      }

      < Modal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        formik={formik}
        setFiles={setFiles}
        files={files}
        handleClose={() => {
          formik.resetForm();
          setFiles([]);
          setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false })
        }
        } />
    </>
  )
}

export default Brand