import { Stack } from '@mui/material'
import React from 'react'

const MenuButton = ({ icon, width, height }) => {
  return (
    <Stack sx={{
      backgroundColor: 'white.main', display: { xs: 'flex' }, cursor: 'pointer', border: '1px solid rgba(0, 0, 0, 0.12)',
      width: width ? width : { xs: '35px', sm: '46px' }, height: height ? height : { xs: '35px', sm: '46px' }, borderRadius: '8px'
    }} justifyContent={'center'} alignItems={'center'}>
      {icon}
    </Stack>
  )
}

export default MenuButton