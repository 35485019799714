import { Autocomplete, TextField, Typography } from '@mui/material'
import React from 'react'

const list = [
  { label: 'Merry Gold', name: 'Merry Gold' },
  { label: 'Merry G', name: 'Merry G' },
  { label: 'Marc Gol', name: 'Marc Gol' },
  { label: 'Merry lea', name: 'Merry lea' },
];

const CommonAutocompleteMultiple = (props) => {
  const { labelinput, data, name, onChange, value, formik, name1 } = props
  let datasss = {
    "id": 2,
    "name": "tulsi gajera"
  }
  console.log('value', value)
  return (
    <>
      {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
      <Autocomplete
        // disablePortal
        multiple
        id="multiple-limit-tags"
        options={data}
        sx={{ width: '100%' }}
        value={value || []}
        onChange={onChange}
        getOptionDisabled={(option) => value && value.some(val => val.id === option.id)}
        getOptionLabel={(option) => option.name}
        error={formik?.touched[props.name] && formik?.errors[props.name]}
        renderInput={(params) => <TextField {...params}
          error={formik?.touched[props.name] && formik?.errors[props.name]}
          helperText={formik?.touched[props.name] && formik?.errors[props.name]}
          label="" placeholder="" />}
      />
    </>
  )
}

export default CommonAutocompleteMultiple