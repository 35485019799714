import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Heading } from '../../components/Common'
import { Box } from '@mui/system'
import { WareHouseDetail } from '../../Service/WareHouse.service'
import { useLocation } from 'react-router-dom'

const WarehouseDetails = () => {

  const { state } = useLocation()
  const [warehouseData, setWarehouseData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  const getWarehouseData = async (id) => {
    const response = await WareHouseDetail(id)
    if (response && response.data) {
      setWarehouseData(response.data)
      setIsLoading(false)
    }
    else {
      setWarehouseData({})
      setIsLoading(false)

    }
  }

  useEffect(() => {
    if (state && state?.id) {
      getWarehouseData(state?.id)
    } else {
      setIsLoading(false)
    }
  }, [])

  const renderContentRow = (label, value) => (
    isLoading ? (
      <Skeleton animation="wave" variant="text" width="100%" height={30} />
    ) : (
      <Stack flexDirection="row">
        <Typography fontWeight={500} fontSize="18px" color="#999" width="200px">
          {label}
        </Typography>
        <Typography fontWeight={500} fontSize="18px">
          {value}
        </Typography>
      </Stack>
    )
  );

  return (
    <>
      <Stack flexDirection="row" justifyContent="flex-start" gap={1} alignItems="center" mb={2}>
        <BackButton />
        <Heading head="Warehouse Details" />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="card_container" p={4}>
            {renderContentRow("Warehouse Name", warehouseData?.warehouseName)}
            {renderContentRow("Company Name", warehouseData?.companyName)}
            {renderContentRow("Owner Name", warehouseData?.ownerName)}
            {renderContentRow("Warehouse Key", warehouseData?.warehouseKey)}
            {renderContentRow("Email", warehouseData?.email)}
            {renderContentRow("Phone Number", warehouseData?.phoneNo)}
            {renderContentRow("GST Number", warehouseData?.gstNo)}
            {renderContentRow("Pan No", warehouseData?.panNo)}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Heading smallHead="Location" />
          <Box className="card_container" p={4}>
            {renderContentRow("Address Line 1", warehouseData?.address1)}
            {renderContentRow("Address Line 2", warehouseData?.address2)}
            {renderContentRow("State", warehouseData?.stateId)}
            {renderContentRow("City", warehouseData?.cityId)}
            {renderContentRow("Zip Code", warehouseData?.zipcode)}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default WarehouseDetails;